<template>
  <div class="wrap"
    :class="[{footer_none: footerNone, none_topfooter: !topfooter}, this.$route.meta.title]"
    >
    <GlobalNavigationBar
      ref="header"
      v-show="showHeader"
      :activeMenuIndex="activeMenuIndex"
      @activeMenu="activeMenu"
       v-if="topfooter"
      :class="$route.meta.headertype"
    />
    <div class="container" :style="{ 'min-height': `${contMinHeight}px` }">
      <router-view/>
      <aside
        class="scroll-top black" style="line-height: 0px;"
        :class="{ on: isScrollToTopOn, upper: isScrollToTopUpper }"
        :style="footerNone ? {bottom: footerHeight+'px'} : {bottom: '8px'}"
        @click.passive="onScrollToTopClick"
        v-show="footCol"
      >
        <span class="arrow">Top</span>
      </aside>
    </div>
    <div class="footer" v-show="showFooter" v-if="topfooter"
      :style="footerNone ? {height: footerHeight+'px'} : {height: 'auto'}"
      :class="{footer_none: footerNone}">
      <GlobalFooter
        ref="footer"
        @activeMenu="activeMenu"
        v-show="footCol"
      />
    </div>
    <modals-container/><!-- vue-js-modal -->
  </div>
</template>

<script>
import '@/assets/m_css/reset.css';
import '@/assets/m_css/common.css';
import GlobalNavigationBar from '@/views/layout/MobileGlobalNavigationBar.vue';
import GlobalFooter from '@/views/layout/MobileGlobalFooter.vue';
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import { AOS } from '@/common/motions';
import MobileDetect from 'mobile-detect';
import { mapGetters } from 'vuex';

export default {
  name: 'MobileContainer',
  mixins: [
    mixinHelperUtils,
  ],
  components: {
    GlobalNavigationBar,
    GlobalFooter,
  },
  data() {
    return {
      showHeader: false,
      showFooter: false,
      isMobil: false,
      isScrollToTopOn: false,
      isScrollToTopUpper: false,
      layoutComponent: 'div',
      /**
       * 메뉴 구분 0:드림플러스 1:마이크로
       */
      activeMenuIndex: 0,
      contMinHeight: window.innerHeight,
      footerOffsetTop: 200,
      isFooter: this.$route.meta.layout,
      fixBtnHeight: 0,
      topfooter: true,
    };
  },
  created() {
    /**
     * 뒤로가기, 앞으로가기 이벤트
     */
    window.addEventListener('popstate', () => {
      this.menuCol(); // 메뉴 구분
    });

    this.isFooter = this.$store.getters.getIsFooter;
    this.fixBtnHeight = this.$store.getters.getFixBtnHeight;
  },
  mounted() {
    // scroll event : https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event
    window.addEventListener('scroll', this.onWindowScroll);

    // AOS 초기화 : https://github.com/michalsnik/aos#api
    AOS.init({
      duration: 900,
      once: true,
      delay: 0,
    });

    if (window.location.pathname === '/programs/hackathon'
        && (sessionStorage.getItem('hackerton') === undefined || sessionStorage.getItem('hackerton') === null)) {
      // 메뉴 구분
      this.topfooter = false;
    } else {
      this.topfooter = true;
    }

    this.headCol(); // header 표시 여부
    this.footCol(); // footer 표시 여부
    this.menuCol(); // 메뉴 구분
  },
  destroyed() {
    // scroll event : https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event
    window.removeEventListener('scroll', this.onWindowScroll);
  },
  methods: {
    getComponent(layout) {
      return this.$options.components[layout || this.$route.meta.layout];
    },
    /**
     * ScrollToTop 클릭 이벤트 처리
     */
    onScrollToTopClick() {
      this.scrollTo(0, 1, 0); // mixinHelperUtils
    },
    onWindowScroll() {
      const windowScrollY = window.scrollY || window.pageYOffset || 0; // ie11
      // 헤더영역 스크롤 이벤트 : 스크롤다운 - 서브메뉴 고정 / 스크롤업 - GNB 보이기
      if (windowScrollY === this.lastWindowScrollY) {
        // ie11 : 스크롤 위치가 같은 경우에는 무시
      } else {
        this.isHeaderSticky = windowScrollY > this.lastWindowScrollY;
        // this.isHeaderSticky = true;
        this.isSubMenuActive = windowScrollY > 68 && windowScrollY < this.lastWindowScrollY;
        // this.isSubMenuActive = windowScrollY > 68;
        this.lastWindowScrollY = windowScrollY;
      }

      if (this.$refs.footer) {
        const windowScreenHeight = window.screen.height;
        const windowInnerHeight = window.innerHeight;
        const footerOffsetTop = this.$refs.footer.$el.offsetTop;
        this.footerOffsetTop = footerOffsetTop;
        // scroll-top 버튼 위치가 브라우저 높이보다 커지면 나타남
        this.isScrollToTopOn = windowScrollY >= (windowScreenHeight - windowScrollY);

        // footer영역에서 scroll-top 버튼 footer 위로 고정
        this.isScrollToTopUpper = windowScrollY >= (footerOffsetTop - windowInnerHeight);
        this.$store.commit('setHeight', this.footerOffsetTop);
      }
    },
    /**
     * Header, Footer 숨김 페이지
     */
    hidePath() {
      const md = new MobileDetect(window.navigator.userAgent);
      const isConditions = [
        // '/startup/apply',
        // '/startup/applyweb',
        // '/startup/applymboileDetail',
        '/center/intranetvisitor',
        /\/contents\/look/];
      let hide = this.isPath(isConditions);
      const path = window.location.pathname;
      if (path.match(/\/startup\/apply/) && (md.mobile() === null)) {
        hide = false;
      }
      return hide;
    },
    /**
     * 메뉴 드림플러스:0,마이크로:1 설정
     */
    menuCol() {
      const isConditions = [/\/event/, /\/reservation/];
      const menu = this.isPath(isConditions);
      if (menu) {
        this.activeMenuIndex = 1;
      } else {
        this.activeMenuIndex = 0;
      }
    },
    isPath(isConditions) {
      const path = window.location.pathname;
      const url = isConditions.some((c) => {
        switch (typeof c) {
          case 'string':
            return c === path;
          default:
            return c.test(path);
        }
      });
      return url;
    },
    /**
     * Header 표시여부
     */
    headCol() {
      this.showHeader = !this.hidePath();
    },
    /**
     * Footer 표시여부
     */
    footCol() {
      if (this.hidePath()) {
        this.showFooter = false;
      } else {
        setTimeout(() => {
          this.showFooter = true;
        }, 1000);
      }
    },
    /**
     * 메뉴 활성화 드림플러스:0,마이크로:1 설정
     */
    activeMenu(index) {
      this.activeMenuIndex = index;
      if (index === 1) {
        this.$router.push('/reservation');
      } else if (index === 0 && this.$route.path !== '/') {
        this.$router.push('/');
      }
    },
  },
  computed: {
    ...mapGetters({
       myIsFooter: 'getIsFooter',
       myFixBtnHeight: 'getFixBtnHeight',
    }),
    footerNone() {
      const v = !this.isFooter;
      return v;
    },
    footerHeight() {
      const v = this.fixBtnHeight;
      return v;
    },
  },
  watch: {
    myIsFooter(val) {
      this.isFooter = val;
    },
    myFixBtnHeight(val) {
      this.fixBtnHeight = val;
    },
  },
};
</script>
